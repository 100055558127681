import React from 'react'

function Introduction() {
    return (
        <div className="Introduction" id="Home">
            <h2>Je suis un développeur frontend<br/>
            <span>passionné qui cherche toujours à apprendre et relever des défis techniques</span>
        </h2>
        </div>
    )
}

export default Introduction